<template>
    <div v-show="!pageDesign.pageStyles.activeSubBlock">
        <div class="card-body px-0">
            <div id="accordion" >
                <div class="r" id="'headingOne'">
                    <strong class="p-3">
                        <span>Page Style</span>
                        <a style="float: right; padding-right:20px; cursor: pointer;" class="arrow-icon-down" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </a>
                    </strong>
                </div>
                <hr>
                <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                    <div class="p-3">
                        <div class="row page-size" v-if="false">
                            <strong class="col-12 ">Page size:</strong>
                            <div class="form-group col-xl-6 col-6 col-md-6 col-lg-4 col-xs-12 col-sm-12 d-flex align-items-center">
                                <label for="" class="mb-0 ">W</label>
                                <input type="number" v-model="pageDesign.pageStyles.width" class="form-control-sm w-100 border-0" >
                            </div>

                            <div class="form-group col-6 col-xl-6 col-md-6 col-lg-4 col-xs-12 col-sm-12 d-flex align-items-center">
                                <label for="" class="mb-0 ">H</label>
                                <input type="number" v-model="pageDesign.pageStyles.height" class="form-control-sm w-100 border-0">
                            </div>
                        </div>

                        
                        
                        <div class="row pl-1 page-padding m-0" v-if="isDesktopView">
                            <p class="col-12 px-0 fz-14">Page paddings:</p>
                            <div class="row">
                                <div class=" col-6 col-xl-6 col-lg-4 col-md-6 col-xs-12 col-sm-12 my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">

                                        <input type="number" min="0" v-model="pageDesign.pageStyles.paddingTop" class="form-control-sm  w-100 input-border-style">
                                    </div>
                                </div>
                            </div>
                                <div class="col-6 col-xl-6 col-md-6 col-lg-4 col-xs-12 col-sm-12 my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z" fill="#B3AFB6"/>
                                        </svg>

                                    </div>
                                    <div class="col-8">
                                    <input type="number" min="0" v-model="pageDesign.pageStyles.paddingBottom" class="form-control-sm  w-100 input-border-style">
                                    </div>
                                </div>
                            </div>
                           
                            </div>
                           
                            <div class="row mt-2">
                            
                            <div class="form-group col-xl-6 col-6 col-lg-4 col-md-6 col-xs-12 col-sm-12  d-flex my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">
                                        <input type="number"  min="0" v-model="pageDesign.pageStyles.paddingLeft" class="form-control-sm  w-100 input-border-style">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-6 col-lg-4 col-md-6 col-xs-12 col-sm-12  my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">
                                        <input type="number" min="0" v-model="pageDesign.pageStyles.paddingRight" class="form-control-sm  w-100 input-border-style">
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        </div>

                        <div classs=" mt-2" v-else>
                             <p class="col-12 px-0 fz-14">Mobile view paddings:</p>
                             <div class="row pl-1 page-padding m-0">
                            <div class="row">
                                <div class="col-6 col-xl-6 col-md-6 col-lg-4 col-xs-12 col-sm-12 my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z" fill="#B3AFB6"/>
                                        </svg>

                                    </div>
                                    <div class="col-8">
                                    <input type="number" min="0" placeholder="Bottom"  v-model="pageDesign.pageStyles.mobilePaddingBottom" class="form-control-sn border-0">
                                    </div>
                                </div>
                            </div>
                            <div class=" col-6 col-xl-6 col-lg-4 col-md-6 col-xs-12 col-sm-12 my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">

                                        <input type="number" min="0" placeholder="Top" v-model="pageDesign.pageStyles.mobilePaddingTop" class="form-control-sn  w-100 border-0">
                                    </div>
                                </div>
                            </div>
                            </div>
                           
                            <div class="row mt-2">
                                <div class="col-6 col-xl-6 col-lg-4 col-md-6 col-xs-12 col-sm-12  my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">
                                        <input type="number" min="0" placeholder="Right" v-model="pageDesign.pageStyles.mobilePaddingRight" class="form-control-sn  w-100 border-0">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 col-6 col-lg-4 col-md-6 col-xs-12 col-sm-12  d-flex my-2">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z" fill="#B3AFB6"/>
                                        </svg>
                                    </div>
                                    <div class="col-8">
                                        <input type="number" min="0" placeholder="Left" v-model="pageDesign.pageStyles.mobilePaddingLeft" class="form-control-sn  w-100 border-0">
                                    </div>
                                </div>
                            </div>
                            </div>
                            
                        </div>
                        </div>
                         <div class="form-group d-flex mb-0 align-items-center py-3">
                        <strong class="mr-3">Background color: </strong>
                        <input type="color" id="background-color"  v-model="pageDesign.pageStyles.backgroundColor"  style="width:35px" class="form-control-color form-control border-0"> 
                         <!-- <label class="my-0 " for="background-color">   </label> -->
                        <input type="text"  class="ColorTextInput input-border-style" v-model="pageDesign.pageStyles.backgroundColor">
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>

<script>
export default {
  props: {
    pageDesign: Object,
    isDesktopView:Boolean,
  },
};
</script>

<style>
a[aria-expanded="true"] i{
    transform: rotate(
180deg
);
}
.arrow-icon-down { color: #000000!important;}
.choose-image-empty {
  padding-bottom: 25px;
  padding-top: 25px;
  background: #f6f6f7;
  border-radius: 10px;
  margin-bottom: 20px;
}
.choose-image-empty > button {
  background: white;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
}
.choose-image-empty:hover {
  border: 1px solid #7f3487;
}
.curs {
  cursor: pointer;
}

.action-buttons .card{
    min-height: 80px!important;
    color: #6D6B6D;
    text-align: center;
        width: 82px;
        transition: 0.5s ease border;
}
.action-buttons .card:hover
{
    border: 1px solid #4D1B7E;
}
.action-buttons .card:active
{
   background-color: #e8e7e9;
}
.action-buttons .card:focus
{
   box-shadow: 0px 0px 10px #4D1B7E;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #4D1B7E!important;
  border:none
}

.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border:none
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border:none
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #C8FFC8;
  border:none
}
.image-upload
{
    /* text-align: center; */
    padding: 12px 15px;
}
.image-upload img
{
        width: 263px;
        /* height: 138px; */
}
.r strong
{
    font-size: 16px;
    font-weight: 500!important;
}
.page-setting-btn
{
    cursor: pointer;
    background: transparent;
    color: #8c8f89;
    padding: 9px 15px;
    border: none;
}
.disabled-card{
    pointer-events: none;
    background: #6D6B6D;
}
.action-buttons p
{
    font-size: 11px;
    font-weight: 700;
}
.page-size label
{
    color: #B3AFB6;
}
.fz-14
{
    font-size: 14px;
}
.fz-16
{
    font-size: 16px;
}
.page-size input, .page-padding input
{
    color: #535355;
    font-weight: 500;
    font-size: 14px;
}
strong
{
    color: #18191C;
    font-size: 14px;
    font-weight: 400!important;
}
/* .page-style
{height: 100%;} */
</style>
